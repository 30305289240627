import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { filter, tap, switchMap } from 'rxjs/operators';
import { CcasUserValidationEnum, ICcasUser } from 'src/app/core/services/ccas/ccas.model';
import { CcasService } from 'src/app/core/services/ccas/ccas.service';
import { DateUtilsService } from 'src/app/core/services/date-utils/date-utils.service';
import { DeviceDetectorService } from 'src/app/core/services/device-detector/device-detector.service';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { FundingInfoUtils } from 'src/app/core/services/loan-application/funding-info/funding-info-utils';
import { ILoanApplication } from 'src/app/core/services/loan-application/loan-application.model';
import { LoanApplicationService } from 'src/app/core/services/loan-application/loan-application.service';
import {
	IAdditionalData,
	IEligibleProducts,
	IMultiProductResult,
	ConfigApiService,
	IConfigResult
} from 'src/app/core/services/mobile-api';
import { MobileApiService } from 'src/app/core/services/mobile-api/mobile-api.service';
import { MultiProductService, ProductTypeEnum } from 'src/app/core/services/multi-product/multi-product.service';
import { IssuingPartnerService } from 'src/app/core/services/partner/issuing-partner.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { DigitUtils } from 'src/app/core/utils/digit-utils';
import { OrganizationUtils } from 'src/app/core/utils/organization-utils';
import { PdfDialogComponent } from 'src/app/shared/components/dialogs/pdf-dialog/pdf-dialog.component';
import { IDebitCard } from 'src/app/core/services/mobile-api';
import { IDisbursementOptions } from 'src/app/core/services/mobile-api/mobile-api.model';
import {
	DisbursementChannelEnum,
	DisbursementType
} from 'src/app/core/services/loan-application/disbursement/disbursement.model';

import { STATE_CA } from '../../shared/constants/states-const';
import { DisbursementUtils } from 'src/app/core/services/loan-application/disbursement/disbursement-utils';
import { STORAGE_KEYS, SessionStorageService } from 'src/app/core/services/storage/session-storage.service';

@Component({
	selector: 'op-loan-completed',
	templateUrl: './loan-completed.component.html',
	styleUrls: ['./loan-completed.component.scss']
})
export class LoanCompletedComponent implements OnInit, AfterViewInit, OnDestroy {
	currentLanguage: string;
	isMobile: boolean = false;
	showCreateButton: boolean = false;
	user: ICcasUser;
	isCreditCardProductEligible: boolean = false;
	eligibleOfferDays: number = 0;
	showOfferDays: boolean = false;
	redirectCode: string;
	digitUrl: string;
	multiProductSegmentNode: string;
	loanApplication: ILoanApplication;
	issuingOrganizationIsOportun: boolean;
	issuingOrganizationIsMeta: boolean;
	issuingOrganization: string;
	multiProductPricingCode: string;
	isCA: boolean;
	customerState: string;
	multiProductCode: string;
	productCategory: string = '';
	hasDebit: boolean;
	creditCardAdditionalData: IAdditionalData = {
		annualFee: '',
		apr: '',
		creditLine: ''
	};
	disbursement: DisbursementUtils;
	fundsAvailability: string;
	cardType: string;
	institutionName: string;
	fundingInfo: string;
	last4Debit: string;
	isHolidayReferralEnabled: boolean;

	constructor(
		private mobileService: MobileApiService,
		private ccasService: CcasService,
		private deviceDetectorService: DeviceDetectorService,
		private loanAppService: LoanApplicationService,
		private mobileApiService: MobileApiService,
		private translocoService: TranslocoService,
		private routingService: RoutingService,
		private dialog: MatDialog,
		private multiProductService: MultiProductService,
		private dateUtilService: DateUtilsService,
		private tagDataService: TagDataService,
		private dialogService: DialogService,
		private issuingPartnerService: IssuingPartnerService,
		private configApiService: ConfigApiService,
		private sessionStorageService: SessionStorageService
	) {}
	private subscription = new Subscription();
	initialized = false;

	ngOnInit(): void {
		const loanApp = this.loanAppService.getLoanApp();
		this.disbursement = DisbursementUtils.fromLoanApp(loanApp);
		this.hasDebit = this.disbursement.isDebitCard();
		this.configApiService.showHolidayReferralSection().subscribe({
			next: (config: IConfigResult) => {
				this.isHolidayReferralEnabled = Boolean(config.value);
				this.sessionStorageService.set(STORAGE_KEYS.HOLIDAY_REFERRAL_ENABLED, this.isHolidayReferralEnabled);
			}
		});
		const loanAppSub = this.loanAppService.loanApplication$.pipe(filter(Boolean)).subscribe({
			next: (resp: ILoanApplication) => {
				this.loanApplication = resp;
				this.customerState = FundingInfoUtils.fromLoanApp(resp).getState();
			}
		});
		this.subscription.add(loanAppSub);

		const issuingOrganizationSub = this.issuingPartnerService.issuingOrganization$
			.pipe(
				tap((issuingOrg) => {
					this.issuingOrganization = issuingOrg;
					this.issuingOrganizationIsOportun = OrganizationUtils.isOportun(this.issuingOrganization);
					this.issuingOrganizationIsMeta = OrganizationUtils.isMetaBank(this.issuingOrganization);
				})
			)
			.subscribe();
		this.subscription.add(issuingOrganizationSub);

		const langSub = this.translocoService.langChanges$.subscribe({
			next: (lang) => {
				this.currentLanguage = lang;
			}
		});
		this.subscription.add(langSub);

		this.isMobile = this.deviceDetectorService.isMobile();

		const cassUserSub = this.ccasService.cassUser$
			.pipe(
				filter(Boolean),
				tap(() => {
					this.showCreateButton = this.ccasService.canCreateAccount();
					this.user = this.ccasService.ccasUser;
				})
			)
			.subscribe({
				next: () => {
					this.initialized = true;
				}
			});
		this.subscription.add(cassUserSub);

		if (this.hasDebit) {
			this.mobileService.getDebitCard(this.loanAppService.loanApplicationId).subscribe({
				next: (debitCard) => {
					this.fundsAvailability = debitCard.fundsAvailability;
					this.cardType = debitCard.cardType;
					this.institutionName = debitCard.institutionName;
					this.last4Debit = debitCard.last4;
					this.fundingInfo = 'ESIGN_CONFIRM_TERM.' + this.fundsAvailability;
				},
				error: (error) => {
					console.error(error);
				}
			});
		}
	}

	ngAfterViewInit(): void {
		const multiProductSub = this.multiProductService
			.checkMultiProductEligibility(this.loanAppService.loanApplicationId)
			.subscribe({
				next: (response: IMultiProductResult) => {
					this.digitUrl = this.checkMultiProductEligibility(response);
				}
			});
		this.subscription.add(multiProductSub);
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	public downloadLoanDocuments(): void {
		this.mobileApiService.getSignedLoanDocuments(this.loanAppService.loanApplicationId).subscribe({
			next: (loanDocument) => {
				const loanDocumentBlob = new Blob([loanDocument], { type: 'application/pdf' });
				const loanDocumentBlobUrl = URL.createObjectURL(loanDocumentBlob);
				const data = {
					title: this.translocoService.translate('VIEW_DOCUMENTS.loanDocuments'),
					url: loanDocumentBlobUrl
				};

				const dialogRef = this.dialog.open(PdfDialogComponent, { data });
				return dialogRef.afterClosed();
			},
			error: (err) => {
				this.dialogService
					.openErrorDialogWithTitle(
						this.translocoService.translate('VIEW_DOCUMENTS.ERROR.docNotAvailableOnline'),
						this.translocoService.translate('VIEW_DOCUMENTS.ERROR.generatingLoanDoc')
					)
					.subscribe();
			}
		});
	}

	public goToMobileService(): void {
		window.open(`${this.ccasService.getCcasHost()}/services/${this.currentLanguage}`, '_blank');
	}

	public goToCreateAccount(): void {
		if (this.ableToCreateCcasUserDirectly()) {
			this.ccasService.createAccount().subscribe({
				next: (rsp) => {
					this.routingService.route(RoutingPathsEnum.createAccount, { queryParams: { created: true } });
				}
			});
		} else {
			this.routingService.route(RoutingPathsEnum.createAccount);
		}
	}

	public goToReferral(): void {
		this.routingService.route(RoutingPathsEnum.referral);
	}

	public trackEligibilityEvents(eventLabel: string): void {
		const tealiumEvent = {
			tealium_event: 'check_multiproduct_eligible',
			event_category: 'CONSUMER_INSTALLMENT_LOAN',
			event_label: eventLabel
		};
		if (eventLabel === 'digit_offer_clicked') {
			tealiumEvent['link_href'] = this.digitUrl;
		}
		this.tagDataService.link(
			{
				loan_application_id: this.loanAppService.loanApplicationId
			},
			tealiumEvent
		);
	}

	private ableToCreateCcasUserDirectly(): boolean {
		const hasEmail = Boolean(this.user.emailAddress);
		const validationResult = this.ccasService.validationResult;

		return (
			hasEmail &&
			[CcasUserValidationEnum.clientNotFound, CcasUserValidationEnum.clientExistsNoOkta].includes(validationResult)
		);
	}

	private trackCreditCardProductEligible(): void {
		if (this.isCreditCardProductEligible) {
			this.trackEligibilityEvents(
				`${this.multiProductSegmentNode}_${this.multiProductPricingCode}_${this.productCategory}_creditCard_offer_viewed`
			);
		} else {
			this.trackEligibilityEvents('creditcard_product_eligible_offer_expired');
		}
	}

	private updateMultiProductEligibleInfo(creditCardProduct: IEligibleProducts, currentDate: string): void {
		this.isCreditCardProductEligible = creditCardProduct ? true : false;
		if (creditCardProduct?.eligibilityEndDate) {
			this.showOfferDays = true;
			this.eligibleOfferDays = this.dateUtilService.getDaysBetweenDates(
				creditCardProduct.eligibilityEndDate,
				currentDate
			);
			if (this.eligibleOfferDays <= 0) {
				this.isCreditCardProductEligible = false;
				this.showOfferDays = false;
			}
		}
	}

	private checkMultiProductEligibility(response: IMultiProductResult): string {
		let digit_url = null;
		if (response.success && response.multiProductEligible) {
			this.trackEligibilityEvents('multi_product_eligible');
			if (response.eligibleProducts?.length) {
				this.trackEligibilityEvents('multi_product_found');
				const creditCardProduct: IEligibleProducts = response.eligibleProducts.find(
					(item) => item.productType.toLowerCase() === ProductTypeEnum.creditCard
				);
				const digitProduct: IEligibleProducts = response.eligibleProducts.find(
					(item) => item.productType.toLowerCase() === ProductTypeEnum.digit
				);
				this.redirectCode = response.redirectCode;
				const currentDate = this.dateUtilService.format(new Date(), 'yyyy-MM-dd');
				this.multiProductSegmentNode = creditCardProduct?.multiProductSegmentNode.toLowerCase();
				this.multiProductPricingCode = creditCardProduct?.multiProductPricingCode.toLowerCase();
				this.multiProductCode = `${this.multiProductSegmentNode}_${this.multiProductPricingCode}`;
				this.productCategory = creditCardProduct?.productCategory
					? creditCardProduct?.productCategory.toLowerCase()
					: this.productCategory;
				this.creditCardAdditionalData = creditCardProduct?.additionalData
					? creditCardProduct?.additionalData
					: this.creditCardAdditionalData;
				if (this.multiProductSegmentNode !== 'mp3') {
					this.updateMultiProductEligibleInfo(creditCardProduct, currentDate);
					this.trackCreditCardProductEligible();
				}
				if (digitProduct) {
					this.trackEligibilityEvents('digit_eligible');
					digit_url = DigitUtils.getDigitUrlApproved(
						this.currentLanguage === 'es' ? 'https://app.oportun.com/download-es' : 'https://app.oportun.com/download',
						this.tagDataService.getTeliumVisitorId(),
						this.issuingOrganizationIsMeta
					);
					this.trackEligibilityEvents('digit_offer_shown');
				}
			} else {
				this.trackEligibilityEvents('multi_product_not_found');
			}
		} else {
			this.trackEligibilityEvents('multi_product_not_eligible');
		}
		return digit_url;
	}
}
