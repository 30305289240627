import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { nanoid } from 'nanoid';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { LandingService } from 'src/app/core/services/landing/landing.service';
import { LoggingService } from 'src/app/core/services/logging/logging.service';
import { ConfigApiService, EventLogApiService, IConfigResult, MfaActionEnum } from 'src/app/core/services/mobile-api';
import {
	ApplicationFlowEnum,
	ILassoResult,
	IVerifyFactorResult
} from 'src/app/core/services/mobile-api/mobile-api.model';
import { IssuingPartnerService } from 'src/app/core/services/partner/issuing-partner.service';
import { RoutingPathsEnum, RoutingService } from 'src/app/core/services/routing/routing.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';
import { TagDataService } from 'src/app/core/services/tag-data/tag-data.service';
import { OrganizationUtils } from 'src/app/core/utils/organization-utils';
import { PhoneTypeEnum } from 'src/app/core/utils/phone-utils';
import {
	SESSION_STORAGE_APP_SOURCE,
	SESSION_STORAGE_REF_ID,
	USER_INFORMATION_STR
} from 'src/app/shared/constants/common-const';

import { INewApplication, IVerifyFactor, IFindELettersResult } from '../../core/services/mobile-api/mobile-api.model';
import { MobileApiService } from '../../core/services/mobile-api/mobile-api.service';
import { IUser } from 'src/app/core/services/token/token.service';
import SingleSignOnService from 'src/app/core/services/single-sign-on/single-sign-on.service';
import { PdfDialogComponent } from 'src/app/shared/components/dialogs/pdf-dialog/pdf-dialog.component';

declare let IGLOO: any;

@Component({
	selector: 'op-multifactor',
	templateUrl: './multifactor.component.html',
	styleUrls: ['./multifactor.component.scss']
})
export class MultifactorComponent implements OnInit, OnDestroy {
	private readonly storageKey = 'mfaId';
	private subscription = new Subscription();

	private mfaId: string;

	formGroupSend: FormGroup;
	formGroupVerify: FormGroup;

	sendCode = true;
	reSendSuccess: boolean = false;
	phoneNumber: string;
	phoneType: string;

	errorMessage: string;

	returnPage: string;
	query: string;
	payload: any;
	applicationFlow: ApplicationFlowEnum;
	isMetaOrg = false;
	actionName: string;

	verifyTypes = [
		{ key: 'sms', text: 'MULTIFACTOR.textMsg' },
		{ key: 'call', text: 'MULTIFACTOR.voiceCall' }
	];
	isFTREnabled = false;

	constructor(
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private mobileService: MobileApiService,
		private landingService: LandingService,
		private routingService: RoutingService,
		private translocoService: TranslocoService,
		private dialogService: DialogService,
		private sessionStorageService: SessionStorageService,
		private configApiService: ConfigApiService,
		private tagDataService: TagDataService,
		private eventLogService: EventLogApiService,
		private issuingPartnerService: IssuingPartnerService,
		private loggingService: LoggingService,
		private singleSignOnService: SingleSignOnService
	) {
		this.createForm(this.formBuilder);
	}

	ngOnInit(): void {
		const issuingOrganizationSub = this.issuingPartnerService.issuingOrganization$
			.pipe(
				tap((issuingOrg) => {
					this.isMetaOrg = OrganizationUtils.isMetaBank(issuingOrg);
				})
			)
			.subscribe();
		this.subscription.add(issuingOrganizationSub);

		const langSub = this.translocoService.langChanges$.pipe(distinctUntilChanged()).subscribe({
			next: () => {
				this.formGroupVerify.get('verificationCode').setErrors(null);
			}
		});
		this.subscription.add(langSub);

		if (!this.routingService.isReloaded()) {
			this.mfaId = this.sessionStorageService.set(this.storageKey, nanoid(10));
		} else {
			this.mfaId =
				this.sessionStorageService.get(this.storageKey) || this.sessionStorageService.set(this.storageKey, nanoid(10));
		}

		this.phoneNumber = this.landingService.getPhoneNumber();
		this.phoneType = this.landingService.getPhoneType();

		if (!this.phoneNumber) {
			this.loggingService.info('phone number is not available');
		}
		if (!this.phoneType) {
			this.loggingService.info('phone type is not available');
		}

		if (this.phoneType != PhoneTypeEnum.cell) {
			this.formGroupSend.get('verifyType').setValue('call');
		}

		this.returnPage = this.route.snapshot.queryParams?.returnPage;
		this.query = this.route.snapshot.queryParams?.query;
		this.actionName = this.route.snapshot.queryParams?.action;

		this.payload = this.sessionStorageService.get('returning');
		this.applicationFlow = this.sessionStorageService.get('applicationFlow') || ApplicationFlowEnum.standard;
		if (this.applicationFlow === ApplicationFlowEnum.fastTrackReturning) {
			this.configApiService.configFastTrackReturnEnabled().subscribe({
				next: (config: IConfigResult) => {
					this.isFTREnabled = Boolean(config.value);
					this.sessionStorageService.set('isFTREnabled', this.isFTREnabled);
				}
			});
		}

		this.sendTagEvent('mfa_shown');
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	get verifyType(): string {
		return this.formGroupSend.get('verifyType').value;
	}

	createForm(fb): void {
		this.formGroupSend = fb.group({
			verifyType: ['sms', Validators.required]
		});

		this.formGroupVerify = fb.group({
			verificationCode: [null, Validators.required]
		});
	}

	onSubmit(post: any): void {
		if (post.hasOwnProperty('verificationCode')) {
			this.submitVerificationCode(post.verificationCode);
		} else {
			this.submitVerifyType(post.verifyType);
		}
	}

	resendCode(): void {
		this.submitVerifyType(this.verifyType, () => {
			this.reSendSuccess = true;
		});
	}

	submitVerificationCode(code: string): void {
		const mfa: IVerifyFactor = {
			phoneNumber: this.landingService.getPhoneNumber(),
			verifyType: this.verifyType,
			action: this.getMfaActionName(this.actionName),
			verificationCode: code,
			mfaVerification: true,
			rememberDevice: true
		};
		// check if the refId exists in sessionStorage, if exists, pass it in the request
		const refId = this.sessionStorageService.get(SESSION_STORAGE_REF_ID);

		this.landingService
			.verifyMultiFactor(mfa, refId)
			.pipe(
				tap(() => {
					// since we found the application, remove the refId from the session
					this.sessionStorageService.remove(SESSION_STORAGE_REF_ID);

					this.formGroupVerify.get('verificationCode').setErrors(null);
					this.errorMessage = '';
					this.sendTagEvent('mfa_success', this.verifyType);
				}),
				switchMap((rsp) => {
					return rsp.applicationId && rsp.applicantId
						? this.submitLasso(rsp).pipe(
								map(() => rsp),
								catchError(() => of(rsp))
						  )
						: of(rsp);
				}),
				switchMap((rsp) => {
					return of(this.continueProcessToRoute(rsp.applicationId === 0));
				})
			)
			.subscribe({
				error: (err) => {
					if (Array.isArray(err?.error) && err.error.some((e) => e?.id === USER_INFORMATION_STR)) {
						this.dialogService.refIdErrorDialog().subscribe({
							next: () => this.routingService.route(RoutingPathsEnum.findYourApplication)
						});
					} else {
						this.formGroupVerify.get('verificationCode').setErrors({ incorrect: true });
						this.errorMessage = Array.isArray(err.error) ? err.error?.find(Boolean).msg : '';
						this.sendTagEvent('mfa_incorrect', this.verifyType);
					}
				}
			});
	}

	private getMfaEventName(tealium_event: string, type: string = null): string {
		const finalType = type ? `${type}_` : ``;
		const mfaMap = {
			mfa_shown: `mfa_shown`,
			mfa_sent: `mfa_${finalType}sent`,
			mfa_success: `mfa_${finalType}success`,
			mfa_incorrect: `mfa_${finalType}incorrect`
		};
		return mfaMap[tealium_event];
	}

	private sendTagEvent(tealium_event: string, type: string = null): void {
		let event = {
			tealium_event,
			mfa_event_name: this.getMfaEventName(tealium_event, type),
			mfa_id: this.mfaId,
			previous_page: this.routingService.getPreviousRoute(),
			current_page: this.routingService.getCurrentRoute(),
			page_location: this.routingService.getCurrentRoute()
		};
		if (type) {
			event['event_action'] = type;
		}
		this.tagDataService.view({ application_type: 'CONSUMER_INSTALLMENT_LOAN' }, event);
	}

	private getMfaActionName(defaultAction: string): MfaActionEnum {
		if (defaultAction) {
			return MfaActionEnum[defaultAction] || '';
		} else {
			const mfaMap = {
				[RoutingPathsEnum.findYourApplication]: MfaActionEnum.continueApplication,
				[RoutingPathsEnum.personalInfo]: MfaActionEnum.personalInfo,
				[RoutingPathsEnum.documentSubmit]: MfaActionEnum.documentUpload,
				[RoutingPathsEnum.plaidConnect]: MfaActionEnum.plaidConnect
			};
			return (
				mfaMap[(this.returnPage && RoutingPathsEnum[this.returnPage]) || this.routingService.getPreviousRoute()] || ''
			);
		}
	}

	private processNonReturning(isApplicationNew: boolean): void {
		if (this.getApplicationFlow() === ApplicationFlowEnum.fastTrackReturning) {
			if (isApplicationNew) {
				this.processFastTrackReturnUserChoice(isApplicationNew, this.isMetaOrg).subscribe();
			} else {
				this.processFastTrackFlow(isApplicationNew).subscribe();
			}
		} else if (this.getApplicationFlow() === ApplicationFlowEnum.fastTrackPrequal) {
			this.routingService.routeByStatus({ replaceUrl: true });
		} else {
			if (isApplicationNew) {
				this.createNewSecuredLoan(RoutingPathsEnum.loanPurpose).subscribe();
			} else {
				this.routingService.routeByStatus({ replaceUrl: true });
			}
		}
	}

	/**
	 *
	 *
	 * @param {boolean} isApplicationNew
	 * @memberof MultifactorComponent
	 */
	private continueProcessToRoute(isApplicationNew: boolean) {
		this.sessionStorageService.remove(this.storageKey);
		const letterMetadataId = this.sessionStorageService.get('letterMetadataId');
		if (this.returnPage) {
			const queryParams = {};
			if (this.query) {
				queryParams[this.query] = true;
			}
			this.routingService.route(RoutingPathsEnum[this.returnPage], { queryParams, replaceUrl: true });
		} else if (letterMetadataId) {
			// goto letters page if this value is present and non-null
			this.displayLetterPdf(letterMetadataId);
		} else {
			this.processNonReturning(isApplicationNew);
		}
	}

	/**
	 *
	 * @param letterMetadataId
	 * @memberof MultifactorComponent
	 *
	 * Displays e-letter to the user
	 */
	private displayLetterPdf(letterMetadataId: any) {
		const letterType = this.sessionStorageService.get('letterType');
		const letterLoanApplicationId = this.sessionStorageService.get('letterLoanApplicationId');
		const letterApplicantId = this.sessionStorageService.get('letterApplicantId');
		this.mobileService
			.getELetter(letterType, letterMetadataId, letterLoanApplicationId)
			.pipe(
				tap((rsp) => {
					//update eventLog
					const eLetterEvent = {
						applicantId: letterApplicantId,
						eventType: `ELECTRONIC_LETTER_ACCESS_${letterType}`,
						loanApplicationId: letterLoanApplicationId
					};
					this.eventLogService.logLoanAppEvent(eLetterEvent).subscribe();
				}),
				switchMap((eLetterDocument) => {
					// open the pdf letter
					const eLetterBlob = new Blob([eLetterDocument], { type: 'application/pdf' });
					const loanDocumentBlobUrl = URL.createObjectURL(eLetterBlob);
					const data = {
						title: this.translocoService.translate('VIEW_DOCUMENTS.loanDocuments'),
						url: loanDocumentBlobUrl
					};
					return this.dialogService.open(PdfDialogComponent, { data }).afterClosed();
				})
			)
			.subscribe({
				next: (rsp) => {
					this.cleanupELetterSessionData();
				},
				error: (err) => {
					//send a error msg to tealium
					const tealiumErrMsg = err?.message?.substring(0, 1000) || 'Error while accessing e-letters';
					this.tagDataService.link(
						{
							applicantId: letterApplicantId,
							loanAppId: letterLoanApplicationId
						},
						{
							tealium_event: 'find_your_letter',
							event_category: 'CONSUMER_INSTALLMENT_LOAN',
							event_action: 'e_letter_application_error',
							event_label: tealiumErrMsg
						}
					);
					this.cleanupELetterSessionData();

					this.dialogService
						.openErrorDialogWithTitle(
							this.translocoService.translate('VIEW_DOCUMENTS.ERROR.docNotAvailableOnline'),
							this.translocoService.translate('VIEW_DOCUMENTS.ERROR.generatingLoanDoc')
						)
						.subscribe();
				}
			});
	}

	private cleanupELetterSessionData() {
		this.sessionStorageService.remove('letterType');
		this.sessionStorageService.remove('letterMetadataId');
		this.sessionStorageService.remove('letterLoanApplicationId');
		this.sessionStorageService.remove('letterApplicantId');
	}

	/**
	 *
	 *
	 * @return {*}  {ApplicationFlowEnum}
	 * @memberof MultifactorComponent
	 */
	private getApplicationFlow(): ApplicationFlowEnum {
		if (this.applicationFlow === ApplicationFlowEnum.fastTrackReturning) {
			return this.isFTREnabled ? ApplicationFlowEnum.fastTrackReturning : ApplicationFlowEnum.standard;
		}
		return ApplicationFlowEnum.standard;
	}

	private submitLasso(app: IVerifyFactorResult): Observable<ILassoResult> {
		// TODO: not for internal devices
		const bbInfo = IGLOO.getBlackbox(); /* cspell: disable-line */
		const lasso = {
			blackBox: bbInfo.blackbox /* cspell: disable-line */,
			captureDate: new Date().toISOString(),
			applicantId: String(app.applicantId),
			loanApplicationId: String(app.applicationId),
			page: this.router.url
		};
		return this.mobileService.lassoCapture(lasso);
	}

	private showErrorMessage(errorMessage: string): Observable<void> {
		const data = {
			icon: 'op-alert',
			title: this.translocoService.translate('DIALOG_MESSAGE.genericTitle'),
			message:
				typeof errorMessage === 'string'
					? errorMessage
					: this.translocoService.translate('ERROR_MESSAGE.genericMessage')
		};
		return this.dialogService.openMessageDialog(
			data,
			() => of(),
			() => of()
		);
	}

	private submitVerifyType(code: string, callback: () => void = null): void {
		const mfa = {
			phoneNumber: this.landingService.getPhoneNumber(),
			verifyType: code,
			mfaVerification: true,
			action: this.getMfaActionName(this.actionName)
		};
		this.sendTagEvent('mfa_sent', code);
		this.landingService.submitMultiFactorCode(mfa).subscribe({
			next: (res) => {
				this.sendCode = false;
				callback && callback();
			},
			error: (err) => {
				this.showErrorMessage(Array.isArray(err?.error) ? err.error.find(Boolean).msg : err.error).subscribe();
			}
		});
	}

	/**
	 *
	 *
	 * @private
	 * @param {boolean} isApplicationNew
	 * @return {*}  {Observable<any>}
	 * @memberof MultifactorComponent
	 */
	private processFastTrackReturnUserChoice(isApplicationNew: boolean, isMetaOrg: boolean): Observable<any> {
		const message = isMetaOrg
			? `</strong><p><p> ${this.translocoService.translate(
					'FAST_TRACK.FTR_PRE_DISCLOSURE.helpToSpeedUp_meta'
			  )} <p><p> <div class="op-label-font op-gray op-mtb-20"> ${this.translocoService.translate(
					'FAST_TRACK.FTR_PRE_DISCLOSURE.legal1_meta'
			  )} </div>`
			: `</strong><p><p> ${this.translocoService.translate(
					'FAST_TRACK.FTR_PRE_DISCLOSURE.helpToSpeedUp'
			  )} <p><p> <div class="op-label-font op-gray op-mtb-20"> ${this.translocoService.translate(
					'FAST_TRACK.FTR_PRE_DISCLOSURE.legal1'
			  )} </div>`;

		const data = {
			title: `${this.translocoService.translate('FAST_TRACK.FTR_PRE_DISCLOSURE.greatToSeeYou')}`,
			message,
			confirmText: this.translocoService.translate('FAST_TRACK.FTR_PRE_DISCLOSURE.agree'),
			cancelText: this.translocoService.translate('FAST_TRACK.FTR_PRE_DISCLOSURE.cancelDesc')
		};

		return this.dialogService.openMessageDialog(
			data,
			(rsp) => this.processFastTrackFlow(isApplicationNew),
			() => this.processStandardFlow(isApplicationNew)
		);
	}

	/**
	 *
	 *
	 * @private
	 * @param {boolean} isApplicationNew
	 * @memberof MultifactorComponent
	 */
	private processFastTrackFlow(isApplicationNew: boolean): Observable<void> {
		this.sessionStorageService.remove('fastTrackStep');
		if (isApplicationNew) {
			this.payload.priorDataAgree = true;
			return this.createNewSecuredLoan(RoutingPathsEnum.fastTrack);
		} else {
			return of(this.routingService.routeByStatus({ replaceUrl: true }));
		}
	}

	/**
	 *
	 *
	 * @private
	 * @param {boolean} isApplicationNew
	 * @return {*}
	 * @memberof MultifactorComponent
	 */
	private processStandardFlow(isApplicationNew: boolean): Observable<void> {
		if (isApplicationNew) {
			this.payload.priorDataAgree = false;
			return this.createNewSecuredLoan(RoutingPathsEnum.loanPurpose);
		} else {
			return of(this.routingService.routeByStatus({ replaceUrl: true }));
		}
	}

	/**
	 *
	 *
	 * @private
	 * @param {*} routeSelected
	 * @memberof MultifactorComponent
	 */
	private createNewSecuredLoan(routeSelected: any): Observable<void> {
		//create the application

		const appSource = this.sessionStorageService.get(SESSION_STORAGE_APP_SOURCE);
		const user: IUser = this.singleSignOnService.getUser();

		if (appSource) {
			this.payload.applicationSource = appSource;
		}

		if (user?.oktaUserId) {
			this.payload.encryptedOktaUserId = user.oktaUserId;
		}

		return this.landingService
			.submitNewSecuredApplication({
				...this.payload
			} as INewApplication)
			.pipe(map(() => this.routingService.route(routeSelected, { replaceUrl: true })));
	}
}
