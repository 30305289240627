import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, RouterOutlet } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { delay, filter, map, tap } from 'rxjs/operators';
import { EventDataService } from 'src/app/core/services/event-data/event-data.service';
import { SessionStorageService } from 'src/app/core/services/storage/session-storage.service';

import { AuthenticationService } from './core/services/authentication/authentication.service';
import { DialogService } from './core/services/dialog/dialog.service';
import { EnvironmentService } from './core/services/environment/environment.service';
import { IdleTimeOutEnum, IdleTimeoutService } from './core/services/idle-timeout/idle-timeout.service';
import { LanguageEnum, LanguageService } from './core/services/language/language.service';
import { LoanApplicationService } from './core/services/loan-application/loan-application.service';
import { LoggingService } from './core/services/logging/logging.service';
import { MobileApiBusyNotifierService } from './core/services/mobile-api-busy-notifier/mobile-api-busy-notifier.service';
import { NeuroIdService } from './core/services/neuro-id/neuro-id.service';
import { RoutingPathsEnum, RoutingService } from './core/services/routing/routing.service';
import { SoftPullService } from './core/services/soft-pull/soft-pull.service';
import { TagDataService } from './core/services/tag-data/tag-data.service';
import { IdleTimeoutDialogComponent } from './shared/components/dialogs/idle-timeout-dialog/idle-timeout-dialog.component';
import { INavObject } from './shared/components/side-nav/side-nav.component';
import { VWOService } from './vendor/wingify/vwo.service';

@Component({
	encapsulation: ViewEncapsulation.None,
	selector: 'op-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
	private idleDialogRef: MatDialogRef<any, any>;

	title = 'Online Origination';

	public navItems: INavObject[] = [
		{
			title: 'NAV.home',
			link: '/'
		},
		{
			title: 'NAV.creditCard',
			fn: this.onCreditCard.bind(this)
		},
		{
			title: 'NAV.howItWorks',
			link: '/how-it-works'
		},
		{
			title: 'NAV.signOut',
			fn: this.cancel.bind(this)
		}
	];

	public navFooterItems: INavObject[] = [
		{
			icon: 'op-earth',
			title: 'NAV.language',
			tagClick: 'change_language',
			fn: this.onChangeSiteLanguage.bind(this)
		},
		{
			icon: 'op-phoneCall',
			title: 'NAV.callUs',
			fn: this.onContactUs.bind(this)
		},
		{
			icon: 'op-location',
			title: 'NAV.locations',
			fn: this.onFindLocation.bind(this)
		}
	];

	private subscription = new Subscription();
	constructor(
		iconRegistry: MatIconRegistry,
		sanitizer: DomSanitizer,
		private route: ActivatedRoute,
		private languageService: LanguageService,
		private authService: AuthenticationService,
		private softPullService: SoftPullService, // instantiate
		private apiBusyService: MobileApiBusyNotifierService,
		private idleTimeoutService: IdleTimeoutService,
		private spinnerService: NgxSpinnerService,
		private loanAppService: LoanApplicationService,
		private tagDataService: TagDataService,
		private routingService: RoutingService,
		private environmentService: EnvironmentService,
		private dialogService: DialogService,
		private sessionStorageService: SessionStorageService,
		private nidService: NeuroIdService, // instantiate,
		private loggingService: LoggingService,
		private eventDataService: EventDataService,
		private vwoService: VWOService
	) {
		this.tagDataService.initConfig();
		this.registerIcons(iconRegistry, sanitizer);
		this.vwoService.initialize();
	}

	ngOnInit(): void {
		this.route.queryParamMap
			.pipe(
				map((params: ParamMap) => params.get('lang')),
				filter(Boolean)
			)
			.subscribe({
				next: (lang: string) => {
					this.languageService.language =
						lang?.toLocaleLowerCase() === 'es' ? LanguageEnum.spanish : LanguageEnum.english;
				}
			});

		this.handleBusySpinner();
		this.handleIdleTimer();
		this.handleAuthChanges();
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	private handleBusySpinner(): void {
		const busySub = this.apiBusyService.busy$.subscribe({
			next: (busy) => {
				return busy ? this.spinnerService.show() : this.spinnerService.hide();
			}
		});
		this.subscription.add(busySub);
	}

	private handleAuthChanges(): void {
		const authSub = this.authService.authorization$.subscribe({
			next: (auth) => {
				const index = this.navItems.findIndex((x) => x.title === 'NAV.signOut');
				//Hide SignOut option in Home / Find the application page
				if (!auth?.token && index >= 0) {
					this.navItems.splice(index, 1);
				}
				//Include SignOut option if token present
				if (auth?.token && index === -1) {
					this.navItems.push({
						title: 'NAV.signOut',
						fn: this.cancel.bind(this)
					});
				}
			}
		});
		this.subscription.add(authSub);
	}

	private handleIdleTimer(): void {
		const idleSub = this.idleTimeoutService.idleTimeout$.subscribe({
			next: (expired) => {
				if (expired === IdleTimeOutEnum.idle) {
					this.idleDialogRef = this.dialogService.open(IdleTimeoutDialogComponent, { minWidth: '350px' });
					const dialogOpenSub = this.idleDialogRef
						.afterOpened()
						.pipe(
							delay(60000), // 1 minute
							tap(() => this.idleDialogRef.close())
						)
						.subscribe();

					this.idleDialogRef
						.afterClosed()
						.pipe(
							tap(() => dialogOpenSub.unsubscribe()),
							map((rsp) => (Boolean(rsp) ? this.confirm() : this.cancel()))
						)
						.subscribe();
				} else if (expired === IdleTimeOutEnum.logOut) {
					if (this.idleDialogRef) {
						this.idleDialogRef.close();
					} else {
						this.cancel();
					}
				}
			}
		});
		this.subscription.add(idleSub);
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	prepareRoute(outlet: RouterOutlet) {
		return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
	}

	confirm(): void {
		this.loanAppService.updateLoanApplication().subscribe();
	}

	cancel(): void {
		this.eventDataService.checkPageDropEvents();
		this.authService.signOut().subscribe({
			complete: () => {
				this.sessionStorageService.clear();
				this.loggingService.info('user sign out');
				this.routingService.route(RoutingPathsEnum.findYourApplication);
			}
		});
	}

	onCreditCard(): void {
		window.location.href = this.languageService.translate('HOME.oportunCreditCardLink');
	}

	onFindLocation(): void {
		this.dialogService.openFindLocationDialog().subscribe();
	}

	onContactUs(): void {
		this.dialogService.openContactUsDialog(Boolean(this.loanAppService.loanApplicationId)).subscribe();
	}

	onChangeSiteLanguage(): void {
		this.languageService.language = this.languageService.isEnglish ? LanguageEnum.spanish : LanguageEnum.english;
	}

	private registerIcons(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer): void {
		const registerIcon = (icon: string) =>
			iconRegistry.addSvgIcon(icon, sanitizer.bypassSecurityTrustResourceUrl(`/assets/images/icons/${icon}.svg`));

		const icons = [
			'op-accountingBills',
			'op-add',
			'op-add2',
			'op-alert',
			'op-alert2',
			'op-alert3',
			'op-alert4',
			'op-ascend',
			'op-arrowLeft',
			'op-atmCard',
			'op-attention',
			'op-attention-orange',
			'op-backgroundCheck',
			'op-bank',
			'op-calendar',
			'op-caretLeft',
			'op-caretRightSmall',
			'op-checkMark-dark-green',
			'op-checkMark-green',
			'op-checkMark-outline',
			'op-checkMark-solid',
			'op-checkMark',
			'op-chevron-right',
			'op-circle-green',
			'op-close',
			'op-cloud',
			'op-cloudUpload',
			'op-connected',
			'op-delete-active.svg',
			'op-delete-focus.svg',
			'op-delete-hover.svg',
			'op-delete.svg',
			'op-document',
			'op-dropdown',
			'op-earth',
			'op-email',
			'op-graphAscend',
			'op-hamburgerMenu',
			'op-help',
			'op-history',
			'op-house',
			'op-info-blue-fill',
			'op-info-green',
			'op-info-grey',
			'op-info',
			'op-location',
			'op-messageBubble',
			'op-mobilePhone',
			'op-moneyBag',
			'op-notifications',
			'op-paperCheck',
			'op-payByCheck',
			'op-pencil',
			'op-phone',
			'op-phoneCall',
			'op-photoCamera',
			'op-plus',
			'op-plus-gray',
			'op-plus-dark-gray',
			'op-popout',
			'op-processing',
			'op-processingInfo',
			'op-protection',
			'op-ready',
			'op-refreshDouble',
			'op-required',
			'op-share',
			'op-smiley',
			'op-star',
			'op-subtract',
			'op-tagCash',
			'op-tagsDouble',
			'op-trash',
			'op-viewOff',
			'op-viewOn',
			'op-clipBoard',
			'op-autoPay',
			'op-car-eligibility'
		];

		icons.forEach(registerIcon);
	}
}
